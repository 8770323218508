import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'

Vue.use(VueRouter)

Vue.use(Vuetify)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/thank-you',
    name: 'ThankYou',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/ThankYou.vue')
  },
  {
    path: '/about-us',
    name: 'AboutUs',
    component: () => import('../views/AboutUs.vue')
  },
  {
    path: '/services',
    name: 'Services',
    component: () => import('../views/Services.vue')
  },
  {
    path: '/minimally-invasive-surgery',
    name: 'MinimallyInvasiveSurgery',
    component: () => import('../views/MinimallyInvasiveSurgery.vue')
  },
  {
    path: '/for-men',
    name: 'ForMen',
    component: () => import('../views/ForMen.vue')
  },
  {
    path: '/contact-us',
    name: 'ContactUs',
    component: () => import('../views/ContactUs.vue')
  },
  {
    path: '/bh',
    name: 'HomeBH',
    component: () => import('../views/HomeBH.vue')
  },
  {
    path: '/bh/about-us',
    name: 'AboutUsBH',
    component: () => import('../views/AboutUsBH.vue')
  },
  {
    path: '/bh/services',
    name: 'ServicesBH',
    component: () => import('../views/ServicesBH.vue')
  },
  {
    path: '/bh/minimally-invasive-surgery',
    name: 'MinimallyInvasiveSurgeryBH',
    component: () => import('../views/MinimallyInvasiveSurgeryBH.vue')
  },
  {
    path: '/bh/for-men',
    name: 'MinimallyInvasiveSurgeryBH',
    component: () => import('../views/ForMenBH.vue')
  },
  {
    path: '/bh/contact-us',
    name: 'ContactUsBH',
    component: () => import('../views/ContactUsBH.vue')
  },
  {
    path: '/bh/thank-you',
    name: 'ThankYouBH',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/ThankYouBH.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
