<template>
  <nav class="fixed z-40 flex flex-wrap items-center justify-between w-full text-gray-800 bg-white lg:pb-2">
    <div class="w-full bg-black text-center text-white p-1 mb-2">
      <p class="text-xs"><a href="/">EN</a> | <a href="/bh">BH</a></p>
    </div>
    <div class="w-full max-w-screen-xl mx-auto">
      <div class="flex items-center justify-between overflow-hidden lg:-mx-4 lg:items-end">
        <div class="relative flex justify-between px-4 lg:static lg:block lg:justify-start">
          <a href="/" class="flex py-2 text-2xl font-bold leading-none uppercase lg:text-3xl whitespace-nowrap">
            <div class="mr-2"><img src="images/icon-logo.png"/></div>
            <div>
              <h1 class="text-lg lg:text-3xl lg:my-1">Dr Marco</h1>
              <p style="font-size:10px">Faria Correa Plastic Surgery</p>
            </div>
          </a>
        </div>
        <div class="flex-shrink-0">
          <div class="flex items-center justify-end px-4">
            <svg xmlns="http://www.w3.org/2000/svg" class="mr-1 lg:mb-1" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M21 16.42v3.536a1 1 0 0 1-.93.998c-.437.03-.794.046-1.07.046-8.837 0-16-7.163-16-16 0-.276.015-.633.046-1.07A1 1 0 0 1 4.044 3H7.58a.5.5 0 0 1 .498.45c.023.23.044.413.064.552A13.901 13.901 0 0 0 9.35 8.003c.095.2.033.439-.147.567l-2.158 1.542a13.047 13.047 0 0 0 6.844 6.844l1.54-2.154a.462.462 0 0 1 .573-.149 13.901 13.901 0 0 0 4 1.205c.139.02.322.042.55.064a.5.5 0 0 1 .449.498z" fill="rgba(90,30,120,1)"/></svg>
            <a href="tel:+6564648075" class="text-lg font-semibold lg:text-2xl"><span>+65 6464 8075</span></a>
          </div>
          <div v-bind:class="{'hidden': !showMenu, 'flex': showMenu}" class="absolute top-0 left-0 z-50 flex-wrap items-start w-4/5 h-screen bg-gray-100 shadow-xl lg:w-full lg:bg-white lg:shadow-none lg:h-auto lg:flex-no-wrap lg:relative lg:flex lg:flex-grow">
            <button class="inline-block mt-4 ml-auto mr-4 cursor-pointer lg:hidden focus:outline-none" type="button" v-on:click="toggleNavbar()">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-11.414L9.172 7.757 7.757 9.172 10.586 12l-2.829 2.828 1.415 1.415L12 13.414l2.828 2.829 1.415-1.415L13.414 12l2.829-2.828-1.415-1.415L12 10.586z" fill="rgba(90,30,120,1)"/></svg>
            </button>
            <ul class="flex flex-col w-full mb-12 ml-auto list-none lg:mb-0 lg:flex-row">
              <li class="nav-item">
                <a href="/" class="flex px-4 pb-4 text-2xl font-bold leading-none uppercase lg:hidden lg:text-3xl whitespace-nowrap">
                  <div class="mr-2"><img src="images/icon-logo.png"/></div>
                  <div>
                    <h1 class="lg:my-1">Dr Marco</h1>
                    <p style="font-size:10px">Faria Correa Plastic Surgery</p>
                  </div>
                </a>
              </li>
              <li class="nav-item">
                <a class="flex items-center px-4 py-4 text-lg leading-snug uppercase lg:py-2" href="/">
                  Home
                </a>
              </li>
              <li class="nav-item">
                <a class="flex items-center px-4 py-4 text-lg leading-snug uppercase lg:py-2" href="about-us">
                  About Us
                </a>
              </li>
              <li class="nav-item">
                <a class="flex items-center px-4 py-4 text-lg leading-snug uppercase lg:py-2" href="services">
                  Services
                </a>
              </li>
              <li class="nav-item">
                <a class="flex items-center px-4 py-4 text-lg leading-snug uppercase lg:py-2" href="minimally-invasive-surgery">
                  Minimally Invasive Surgery
                </a>
              </li>
              <li class="nav-item">
                <a class="flex items-center px-4 py-4 text-lg leading-snug uppercase lg:py-2" href="for-men">
                  For Men
                </a>
              </li>
              <li class="nav-item">
                <a class="flex items-center px-4 py-4 text-lg leading-snug uppercase lg:py-2" href="contact-us">
                  Contact Us
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <button class="inline-block w-full px-4 py-2 cursor-pointer lg:hidden focus:outline-none bg-sitePurple" type="button" v-on:click="toggleNavbar()">
        <svg class="mx-auto" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M3 4h18v2H3V4zm0 7h18v2H3v-2zm0 7h18v2H3v-2z" fill="rgba(255,255,255,1)"/></svg>
      </button>
    </div>
  </nav>
</template>

<script>
export default {
  name: "emerald-navbar",
  data() {
    return {
      showMenu: false
    }
  },
  methods: {
    toggleNavbar: function(){
      this.showMenu = !this.showMenu;
    }
  }
}
</script>